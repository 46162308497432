import React from 'react';
import API from '../API';
import {Link} from 'react-router-dom';

class Programs extends React.Component {
    state = {data: null};

    componentDidMount(){

        API.get_programs_data()
          .then(res => {
              if(res.data.errorStatus === 1){
                  this.setState({data: null});
                  return;
              }
              this.setState({data: res.data});
          }).catch(e => {
              console.error("Error occured: " + e);
          });
    }

    render(){
        if(!this.state.data){
            return (
              <div></div>
            );
        }

        return (
            <div>
                <h2 className="page-header">Our Programs <small>Shaping the young generation</small><hr /></h2>
                <div className="row">
                    {this.state.data.map((program, index) => (
                        <div className="col-md-6 col-sm-12" key={"prog_" + index}>
            				<div className="panel panel-default text-center">
            					<div className="panel-heading home_program">
            						<img src={program.banner} alt="pic" />
            					</div>
            					<div className="panel-body">
            						<h4>{program.title}</h4>
            						<p>{program.subtitle}</p>
                                    <Link className="btn btn-primary" to="/programs">Learn More</Link>
            					</div>
            				</div>
            			</div>
                    ))}

        		</div>
            </div>
        );
    }
}

export default Programs;
