import React from 'react';
import API from '../API';

class About extends React.Component {
    state = {data: null};

    componentDidMount(){

        API.get_aboutus_data()
          .then(res => {
              if(res.data.errorStatus === 1){
                  this.setState({data: null});
                  return;
              }

              this.setState({data: res.data});
          }).catch(e => {
              console.error("Error occured: " + e);
          });
    }

    render(){
        if(!this.state.data){
            return (
              <div></div>
            );
        }

        return (
            <div>
                <h1 className="page-header">{this.state.data.title} <small>{this.state.data.subtitle}</small><hr /></h1>
                <div className="row">
                    <div className="col-md-12" dangerouslySetInnerHTML={{__html:this.state.data.detail}}></div>
                </div>
            </div>
        );
    }
}

export default About;
