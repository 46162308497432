import React from 'react';
import ContactForm from '../components/ContactForm';

var ContactUs  = function(){


        return(
            <div>
                <h1 className="page-header">Contact <small>Our location</small><hr /></h1>

                <div className="row">

                    <div className="col-md-8">
                        <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d7155.282766211074!2d85.948802!3d26.2733!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0xda6e9eb2643c8898!2sBazm-e-Adab%20office!5e0!3m2!1sen!2sin!4v1571548400725!5m2!1sen!2sin" width="100%" height="400" frameBorder="0" allowFullScreen=""></iframe>

                    </div>

                    <div className="col-md-4">
                        <h3>Contact Details</h3>
                        <p>
                            Bazm-e-Adab<br />Dighair, Darbhanga, INDIA 847121<br />
                        </p>
                        <p><i className="fa fa-phone"></i>
                            <abbr title="Phone"></abbr>: (+91) 7428-673419</p>
                        <p><i className="fa fa-envelope-o"></i>
                            <abbr title="Email"></abbr>: <a href="mailto:bazmeadab.dbg@gmail.com">bazmeadab.dbg@gmail.com</a>
                        </p>

                    </div>
                </div>

                <ContactForm />
            </div>
        );

}

export default ContactUs;
