import React from 'react';
import API from '../API'

class ContactForm extends React.Component{
    captchaUrl = API.captchaURL();

    state = {
        elmData: {name:"", phone:"", email:"", message:"", captcha: "", captchaSrc: this.captchaUrl},
        elmErr : {name:"", phone:"", email:"", message:"", captcha: ""}
    };

    valid_criteria = {
        name: {
            required: true,
            regEx: /^[a-zA-Z ]*$/
        },
        phone:{
            regEx: /^\d{10,12}$/
        },
        email:{
            regEx: /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/
        },
        message:{
            required: true
        },
        captcha:{
            required: true
        }
    }

    feildValueChanged = (e) =>{
        let st = {...this.state};
        st.elmData[e.target.id] = e.target.value;
        this.setState(st);
    }

    validate = (e) =>{
        let id = e.target.id, val = e.target.value.trim();
        let st = {...this.state};
        st.elmErr[id] = "";

        if(this.valid_criteria[id]){
            if(this.valid_criteria[id]['required'] && val.length === 0){
                st.elmErr[id] = "This feild is required.";
            }else if(this.valid_criteria[id]['regEx'] && val.length>0 && !this.valid_criteria[id]['regEx'].test(val)){
                st.elmErr[id] = id + " is invalid.";
            }
        }
        this.setState(st);
    }

    refreshCaptcha = () =>{
        let st = {...this.state};
        st.elmData["captchaSrc"] = this.captchaUrl  + Math.random();
        this.setState(st);
    }

    submitFormHandler = (event) => {
        event.preventDefault();
        let self = this;
        if(!this.formIsValid()){
            return;
        }
        API.submitContactForm(this.state.elmData).then(function (response) {
            if(response.data.error){
                alert(response.data.error);
            }else{
                self.clearForm();
                alert("Message sent successfully.")
            }

        }).catch(function (error) {
            console.log(error);
        });
    }

    clearForm(){
        let st = {...this.state};
        for (let key in st.elmErr) {
            if (st.elmErr.hasOwnProperty(key)) {
                st.elmErr[key] = "";
            }
        }
        for (let key in st.elmData) {
            if (st.elmData.hasOwnProperty(key)) {
                st.elmData[key] = "";
            }
        }
        this.setState(st);
    }

    formIsValid(){

        let elmData = this.state.elmData;
        let st = {...this.state};
        let valid = true;
        let phoneRegEx = /^\d+$/;

        for (let key in st.elmErr) {
            if (st.elmErr.hasOwnProperty(key)) {
                st.elmErr[key] = "";
            }
        }
        try {
            if(elmData.name.trim().length === 0){
                st.elmErr.name = "This feild is required.";
                valid = false;
            }else if ((elmData.name.trim().length > 0) && !(this.valid_criteria.name.regEx.test(elmData.name.trim()))){
                st.elmErr.name = "name is invalid.";
                valid = false;
            }
            if ((elmData.phone.trim().length > 0) && (!(this.valid_criteria.phone.regEx.test(elmData.phone.trim())) )){
                st.elmErr.phone = "phone is invalid";
                valid = false;
            }
            if ((elmData.email.trim().length > 0) && !(this.valid_criteria.email.regEx.test(elmData.email.trim()))){
                st.elmErr.email = "Email is invalid.";
                valid = false;
            }
            if(elmData.message.trim().length === 0){
                st.elmErr.message = "This feild is required.";
                valid = false;
            }
            if(elmData.captcha.trim().length === 0){
                st.elmErr.captcha = "This feild is required.";
                valid = false;
            }

            if(valid){
                this.setState({elmErr : {name:"", phone:"", email:"", message:"", captcha: ""}});
            }else{
                this.setState(st);
            }
        }catch(err) {
              valid = false;
              console.error(err);
        }

        return valid;
    }


    render(){

        let elmData = this.state.elmData;
        let elmErr = this.state.elmErr;

        return (
            <div className="row">
    			<div className="col-md-8">
    				<h3>Send us a Message</h3>
    				<form onSubmit={this.submitFormHandler} data-toggle="validator" role="form">
    					<div className="control-group form-group">
    						<div className="controls">
    							<label>Name:</label>
    							<input type="text" className="form-control" id="name" maxLength="32" value={elmData.name} onChange={this.feildValueChanged} onBlur={this.validate} />
    							<p className="form-fld-err">{elmErr.name}</p>
    						</div>
    					</div>
    					<div className="control-group form-group">
    						<div className="controls">
    							<label>Phone Number:</label>
    							<input type="text" className="form-control" id="phone" maxLength="15" value={elmData.phone} onChange={this.feildValueChanged} onBlur={this.validate} />
                                <p className="form-fld-err">{elmErr.phone}</p>
    						</div>
    					</div>
    					<div className="control-group form-group">
    						<div className="controls">
    							<label>Email Address:</label>
    							<input type="text" className="form-control" id="email" maxLength="64" value={elmData.email} onChange={this.feildValueChanged} onBlur={this.validate} />
                                <p className="form-fld-err">{elmErr.email}</p>
    						</div>
    					</div>
    					<div className="control-group form-group">
    						<div className="controls">
    							<label>Message:</label>
    							<textarea rows="10" cols="100" className="form-control" value={elmData.message} id="message" maxLength="999" style={{"resize":"none"}} onChange={this.feildValueChanged}  onBlur={this.validate} >

                                </textarea>
                                <p className="form-fld-err">{elmErr.message}</p>
    						</div>
    					</div>

    					<div className="control-group form-group">
    						<div className="controls">
    							<label>Captcha:</label>
    							<input className="form-control" style={{"width":"25%", "display":"inline"}} type="text" id="captcha" maxLength="5" value={elmData.captcha} onChange={this.feildValueChanged}  onBlur={this.validate} />&nbsp;

    							<img src={elmData.captchaSrc} id='captchaimg' alt="captcha" />
    							<small>
    								<a onClick={this.refreshCaptcha} style={{cursor: "pointer", color: "green"}} >refresh</a>
    							</small>
                                <p className="form-fld-err">{elmErr.captcha}</p>
    						</div>
    					</div>

    					<button type="submit" className="btn btn-primary">Send Message</button>
    				</form>
    			</div>

    		</div>
        );
    }
}

export default ContactForm;
