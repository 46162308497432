import React from 'react';
import API from '../API';

class Programs extends React.Component {
    state = {data: null};

    componentDidMount(){

        API.get_programs_data()
          .then(res => {
              if(res.data.errorStatus === 1){
                  this.setState({data: null});
                  return;
              }
              this.setState({data: res.data});
          }).catch(e => {
              console.error("Error occured: " + e);
          });
    }

    render(){
        if(!this.state.data){
            return (
              <div></div>
            );
        }

        return (
            <div>
                <h1 className="page-header">Programs <small> By Bazm</small><hr /></h1>

                {this.state.data.map((program, index) => (
                    <div key={"prog_" + index}>
                        <div className="row">
                			<div className="col-md-5">
                				<img className="img-fluid img-hover" src={program.banner} alt="" />
                			</div>
                			<div className="col-md-7">
                				<h3>{program.title} <small>{program.subtitle}</small></h3>
                                <p dangerouslySetInnerHTML={{__html:program.detail}}></p>
                			</div>
                		</div>
                        <hr />
                    </div>


                ))}
            </div>
        );
    }
}

export default Programs;
