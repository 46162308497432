import React from 'react';
import { SRLWrapper } from "simple-react-lightbox";
import SimpleReactLightbox from "simple-react-lightbox";

class GalleryComponent extends React.Component{

    state = {loadingFinished : false};
    imageCount = 0;
    imageLoaded = 0;

    handleIamgeLoaded = () => {
        this.imageLoaded++;
        this.checkCompletion();
    }

    handleIamgeErrored = () => {
        this.imageLoaded++;
        this.checkCompletion();
    }

    checkCompletion(){
        if(this.imageLoaded === this.imageCount){
            this.setState({loadingFinished : true})
        }
    }

    renderLoader(){
        if(this.state.loadingFinished) 
            return null;
        return <div className="gal_img_conainer_overlay"><div className="loader"></div></div>;
    }

    render(){
        let photos = this.props.photos;
        let title = this.props.title;
        let coverPhotoOnly = this.props.coverPhotoOnly;
        let cover;
        this.imageCount = photos.length;

        return (
            <SimpleReactLightbox>
            <SRLWrapper>
            {
                photos.map((photo, i) => {
                    cover = "";
                    if(coverPhotoOnly && i>0){
                        cover = "none";
                    }

                    return (
                        <div className="gal_img_conainer" key={title+i} style={ coverPhotoOnly ? {"display" : cover} : {"display" : cover, float: "left"}}>
                            { this.renderLoader() }
                            <a href={photo.pic} data-attribute="SRL">
                                <img src={photo.thumb} className="article_gallery_img" onLoad= {this.handleIamgeLoaded} onError= {this.handleIamgeErrored} alt={title} />
                            </a>
                        </div>
                    );
                })
            }
            </SRLWrapper>
            </SimpleReactLightbox>
        );
    }
}

export default GalleryComponent;
