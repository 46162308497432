import React from 'react';
import API from '../API';

class History extends React.Component{

    state = {data: null};

    componentDidMount(){

        API.get_bazm_history()
          .then(res => {
              if(res.data.errorStatus === 1){
                  this.setState({data: null});
                  return;
              }

              this.setState({data: res.data});
          }).catch(e => {
              console.error("Error occured: " + e);
          });
    }

    render(){
        if(!this.state.data){
            return (
              <div></div>
            );
        }

        return (
            <div>
                <h1 className="page-header">Our History<small> Birth Of Bazm</small><hr /></h1>

        		<img className="img-fluid"  src={this.state.data.banner} alt="" />

        		<hr />
        		<span dangerouslySetInnerHTML={{__html:this.state.data.detail}}></span>
            </div>
        );
    }
}

export default History;
