import React from 'react';
import {BrowserRouter, Switch, Route} from 'react-router-dom';
import Nav from './components/Nav';
import BannerHeader from './components/BannerHeader';
import Sidebar from './components/Sidebar';
import Home from './pages/Home';
import History from './pages/History';
import Programs from './pages/Programs';
import Event from './pages/Event';
import Gallery from './pages/Gallery';
import ContactUs from './pages/ContactUs';
import Donation from './pages/Donation';
import Notfound from './pages/Notfound';

function App() {
  return (
     <BrowserRouter>
        <Nav />
        <div className="container">
            <BannerHeader />
            <div className="row content_container">
	           <div className="col-md-9">
                    <Switch>
                        <Route path="/" exact component={Home} />
                        <Route path="/event/:handle" component={Event} />
                        <Route path="/history" component={History} />
                        <Route path="/programs" component={Programs} />
                        <Route path="/contactus" component={ContactUs} />
                        <Route path="/gallery" component={Gallery} />
                        <Route path="/donation" component={Donation} />
                        <Route path="*" component={Notfound} />
                    </Switch>
                </div>
                <div className="col-md-3">
                    <Sidebar />
                </div>
            </div>
        </div>
      </BrowserRouter>
  );
}

export default App;
