
const monthArr = {'01': 'Jan', '02': 'Feb', '03': 'Mar', '04': 'Apr', '05': 'May', '06': 'Jun', '07': 'July', '08': 'Aug', '09': 'Sept', '10': 'Oct', '11': 'Nov', '12': 'Dec'};

export function highlightNavMenu(link){
    let navbar = document.getElementById("collapsibleNavbar").children[0];
    let toSelect;
    for(let i=0; i<navbar.children.length; i++){
        let li = navbar.children[i];
        if(li.classList.contains("active")){
            li.classList.remove("active");
        }

        if(li.children[0].getAttribute("href") === link){
            toSelect = li;
        }
    }

    toSelect.classList.add("active");
}

export function dateFormat(dateStr){
    let dt = dateStr.split(" ")[0].split("-");
    return dt[2] + " " + monthArr[dt[1]] + " " + dt[0];
}
