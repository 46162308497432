import React, { Component } from 'react';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from 'react-responsive-carousel'; //autoPlay

class BannerHeader extends Component {
    render() {
        return (
            <Carousel showThumbs={false} showStatus={false} showArrows={true} autoPlay infiniteLoop >
                <div>
                    <img src="/images/heading_banners/banner1_1200x350.png" alt="" />
                    <p className="legend_override">Education is the most powerful weapon which you can use to change the world: Nelson Mandela</p>
                </div>
                <div>
                    <img src="/images/heading_banners/banner2_1200x350.png" alt=""  />
                    <p className="legend_override">Some of the brightest minds in the country can be found on the last benchers of the classroom</p>
                </div>
                <div>
                    <img src="/images/heading_banners/banner3_1200x350.png"  alt="" />
                    <p className="legend_override">Education is an ornament in prosperity and a refuge in adversity.</p>
                </div>
            </Carousel>
        );
    }
};

export default BannerHeader;
