import React from 'react';
import About from '../components/About';
import Programs from '../components/Programs';
import Events from '../components/Events';

class Home extends React.Component {

    render(){
          return (
            <div>
                <About />
                <Programs />
                <Events />
            </div>
          );
      }
}

export default Home;
