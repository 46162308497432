import React from 'react';
import { Modal, Table } from 'react-bootstrap';

class Donation extends React.Component{

    render(){


        return (
            <div>
                <h1 className="page-header">Donate for Cause<small> To Bazm</small><hr /></h1>
                
                <h6 style={{color:"red"}}> *Only For Indian Donor</h6>
        		<span>Your donation will further our vision of ensuring a literate village. Donate to make a change.</span>
                <hr />

                <h4>Bank Account Detail</h4>

                <Table striped bordered hover size="sm">
                    <tbody>
                        <tr><td>Name</td><td> Bazm e Adab</td></tr>
                        <tr><td>Bank: </td><td> HDFC Bank</td></tr>
                        <tr><td>Account No: </td><td> 50200039689021</td></tr>
                        <tr><td>Account Type: </td><td> Current</td></tr>
                        <tr><td>IFSC Code:</td><td>  HDFC0002851</td></tr>
                    </tbody>
                </Table>

                <hr />
                <br /><br />
                <h4>QR code For UPI Payments</h4>
                <hr />
                <div className="row">
                    <div className="col-md-6 col-sm-12"><img id="fbBtn" src="/images/bazm-qr.jpeg" alt=""/></div>
                    <div className="col-md-6 col-sm-12"><img id="fbBtn" src="/images/upi.png" alt=""/></div>
                </div>
                
                
                
            </div>
        );
    }
}

export default Donation;
