import axios from 'axios';
import querystring from 'querystring';
class API{

    static SERVICE_URL = "http://api.bazmeadab.org/";

    static fetch(url){
        return axios.get(url, {withCredentials: true});
    }

    static post(url, formData){
        return axios.post(url, querystring.stringify(formData), {withCredentials: true});
    }

    static get_aboutus_data(){
        return this.fetch(this.SERVICE_URL + "?__action=aboutus");
    }

    static get_bazm_history(){
        return this.fetch(this.SERVICE_URL + "?__action=bazmhistory");
    }

    static get_programs_data(){
        return this.fetch(this.SERVICE_URL + "?__action=programs");
    }

    static get_events_data(){
        return this.fetch(this.SERVICE_URL + "?__action=events");
    }

    static get_event_and_photo_by_seo_title(seo_title){
        return this.fetch(this.SERVICE_URL + "?__action=event_and_photo&seo_title=" + seo_title);
    }

    static photo_gallery(){
        return this.fetch(this.SERVICE_URL + "?__action=photo_gallery");
    }

    static submitContactForm(formData){
        return this.post(this.SERVICE_URL + "?__action=contactForm", formData);
    }

    static captchaURL(){
        return this.SERVICE_URL + 'captcha/captcha.php?';
    }

}

export default API;
