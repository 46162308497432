import React from 'react';
import { withRouter } from "react-router";


let Sidebar = function(props){

    let showDonationLink = !props.location.pathname.includes("/donation")

    return(
      <div id="sidebar">

        { showDonationLink &&
            <div className="row">
                <div className="col-md-12">
                    <div className="card  text-center">
                        <h5 className="card-header">Donate for Cause</h5>
                        <div className="card-body">
                            <h5 className="card-title">&nbsp;</h5>
                            <p className="card-text">Your donation will further our vision of ensuring a literate village.
                			Donate to make a change.</p>
                            <a href="/donation" className="btn btn-primary">Donate</a>

                        </div>
                    </div>
                </div>
            </div>
        }

        {/* PhotoGallery

            <div className="row">
                <div className="col-md-12">
                    <div className="card  text-center">
                        <h5 className="card-header">Photo Gallery</h5>
                        <div className="card-body">
                            <h5 className="card-title"></h5>
                            <p className="card-text">Checkout Quiz and Matric Crash Course snaps.</p>
                            <a href="/gallery" className="btn btn-primary">Gallery</a>
                        </div>
                    </div>
                </div>
            </div>
        */}

        <div className="row">
            <div className="col-md-12">
                <div className="card  text-center">
                    <h5 className="card-header">We On Facebook</h5>
                    <div className="card-body">
                        <h5 className="card-title">&nbsp;</h5>
                        <p className="card-text"></p>
                        <a href="https://www.facebook.com/BazmeAdab.Dighiar.Darbhanga" target="_blank"><img id="fbBtn" src="/images/findOnFb.png" alt=""/></a>
                    </div>
                </div>
            </div>
        </div>

        <div className="row">
            <div className="col-md-12">
                <div className="card  text-center">
                    <h5 className="card-header">Ongoing Activities</h5>
                    <div className="card-body">
                        <h5 className="card-title">&nbsp;</h5>
                        <div className="card-text" style={{textAlign: "left"}}>
                            <ol>
                                <li>Matriculation Preparation</li>
                                <li>Two year(9th & 10th) foundation program</li>
                                <li>Student Regular Counselling</li>
                            </ol>
                        </div>
                    </div>
                </div>
            </div>
        </div>

      </div>
    );
};

export default withRouter(Sidebar);
