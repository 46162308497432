import React from 'react';
import API from '../API';
import Notfound from './Notfound';
import GalleryComponent from '../components/GalleryComponent';
import {dateFormat} from '../util/Utility'

class Event extends React.Component{

    state = {data: null};

    componentDidMount(){
        const { handle } = this.props.match.params;
        API.get_event_and_photo_by_seo_title(handle)
          .then(res => {
              if(res.data.errorStatus === 1){
                  this.setState({data: null});
                  return;
              }
              this.setState({data: res.data});
          }).catch(e => {
              this.setState({data: "pagenotfound"});
              console.error("Error occured: " + e);
          });
    }

    render(){
        if(!this.state.data){
            return (
              <div></div>
            );
        }

        if(this.state.data === "pagenotfound"){
            return (
              <Notfound />
            );
        }
        let event = this.state.data;

        return (
            <div>
                <h1 className="page-header">{event.title} <small> By Bazm</small></h1>
                <p><i className="fa fa-clock-o"></i> Posted on {dateFormat(event.createdDate)}</p>

                <img className="img-fluid" src={event.banner} alt="banner" />
    			<hr />

                <p  dangerouslySetInnerHTML={{__html:event.detail}}></p>
        		<hr />

                <GalleryComponent photos={event.photos} title={event.title} coverPhotoOnly={false} />

            </div>
        );
    }
}

export default Event;
