import SimpleReactLightbox from "simple-react-lightbox";
import GalleryComponent from '../components/GalleryComponent';
import React from 'react';
import API from '../API';


class Gallery extends React.Component{

    state = {data: null};

    componentDidMount(){

        API.photo_gallery()
          .then(res => {
              if(res.data.errorStatus === 1){
                  this.setState({data: null});
                  return;
              }

              this.setState({data: res.data});
          }).catch(e => {
              console.error("Error occured: " + e);
          });
    }

    render(){
        if(!this.state.data){
            return (
              <div></div>
            );
        }
        let gallaries = this.state.data;

        return (
            <SimpleReactLightbox>
                <h1 className="page-header">Image Gallery <small></small><hr /></h1>

                <div className="row">
                {
                    gallaries.map((gallery, i) => {
                        return (
                            <div className="col-md-3 col-sm-6" key={i+gallery.title}>


                                <div className="card  text-center">
                                    <div className="card-body">
                                        <h5 className="card-header">
                                        <GalleryComponent photos={gallery.photos} title={gallery.title} coverPhotoOnly={true} />
                                        </h5>
                                        <p className="card-text">{gallery.title}</p>


                                    </div>
                                </div>

                            </div>
                        );
                    })
                }
                </div>
            </SimpleReactLightbox>
        );
    }
}

export default Gallery;
