import React from 'react';
import API from '../API';
import {Link} from 'react-router-dom';

class Events extends React.Component {
    state = {data: null};

    componentDidMount(){
        API.get_events_data()
          .then(res => {
              if(res.data.errorStatus === 1){
                  this.setState({data: null});
                  return;
              }
              this.setState({data: res.data});
          }).catch(e => {
              console.error("Error occured: " + e);
          });
    }

    render(){
        if(!this.state.data){
            return (
              <div></div>
            );
        }

        return (
            <div>
                <h2 className="page-header">Events!<hr /></h2>
                <div className="row">
                    {this.state.data.map((event, index) => (

                        <div className="col-md-6  col-sm-12" key={"evnt_"+index}>
            				<div className="media">
            					<div className="pull-left">
            						<span className="fa-stack fa-2x">
            							  <i className="fa fa-circle fa-stack-2x text-primaryy"></i>
            							  <i className="fa fa-asterisk fa-stack-1x fa-inverse"></i>
            						</span>
            					</div>
            					<div className="media-body">
            						<h5 className="media-heading">{event.title}</h5>
                                    <Link to={`event/${event.title_seo_url}`}>Read in Detail</Link>
            					</div>
            				</div>
            			</div>


                    ))}

        		</div>
            </div>
        );
    }
}

export default Events;
