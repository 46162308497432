import React from 'react';
import {Link} from 'react-router-dom';
import {highlightNavMenu} from '../util/Utility';


class Nav  extends React.Component{

    componentDidMount(){
        let urlPath = window.location.pathname;
        let navbarNav = document.getElementById("collapsibleNavbar").children[0];

        for(let i=0; i<navbarNav.children.length; i++){
            let li = navbarNav.children[i];
            let href = li.children[0].getAttribute("href");
            // console.log(href);
            if(href === urlPath){
                li.classList.add("active");
            }
        }
    }

    render(){
          return (
              <nav className="navbar navbar-expand-md bg-yellow navbar-dark fixed-top">
                  {/* Brand */}
                  <a className="navbar-brand" href="/#">
                        <img className="d-none d-md-block" src="/images/logo.png" alt="Bazm-e-Adab" />
                        <img className="d-block d-sm-none" src="/images/logo_mobile.png" alt="Bazm-e-Adab" />
                  </a>

                  {/* Toggler/collapsibe Button */}
                  <button className="navbar-toggler collapsed" id="navbarToggler" type="button" data-toggle="collapse" data-target="#collapsibleNavbar"  onClick={this.onToggleMenu} >
                    <span className="navbar-toggler-icon"></span>
                  </button>

                  {/* Navbar links */}
                  <div className="collapse navbar-collapse  justify-content-end " id="collapsibleNavbar">
                    <ul className="navbar-nav">
                      <li className="nav-item"  onClick={this.changeActive}>
                        <Link className="nav-link" to="/">Home</Link>
                      </li>
                      <li className="nav-item" onClick={this.changeActive}>
                        <Link className="nav-link" to="/history">History</Link>
                      </li>
                      <li className="nav-item"  onClick={this.changeActive}>
                        <Link className="nav-link" to="/programs">Programs</Link>
                      </li>
                      <li className="nav-item"  onClick={this.changeActive}>
                        <Link className="nav-link" to="/gallery">Photo Gallery</Link>
                      </li>
                      <li className="nav-item"  onClick={this.changeActive}>
                        <Link className="nav-link" to="/contactus">Contact Us</Link>
                      </li>
                    </ul>
                  </div>
            </nav>
          );
    }

    onToggleMenu  = () => {
        let btn = document.getElementById("navbarToggler");;
        let collapsibleNavbar = document.getElementById("collapsibleNavbar");
        if(btn.classList.contains("collapsed")){
            collapsibleNavbar.classList.add("show");
            btn.classList.remove("collapsed");
        }else{
            collapsibleNavbar.classList.remove("show");
            btn.classList.add("collapsed");
        }
    };

    changeActive = (e) => {
        let toSelect = e.currentTarget.children[0].getAttribute("href");
        highlightNavMenu(toSelect);
        setTimeout(this.onToggleMenu, 300);
    }
}

export default Nav;
