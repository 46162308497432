import React from 'react';
import API from '../API';

class Notfound extends React.Component{

    render(){

            return (
              <div style={{color: "#d6b014", height: "100%", marginTop: "20%", fontSize: "300%"}}>
                Ye kahan aa gye aap!<br />
                404 - Page Not Found.
              </div>
            );

    }
}

export default Notfound;
